import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export const appSettings = createSettingsParams<{
  videoUrl: SettingsParamType.String;
}>({
  videoUrl: {
    getDefaultValue() {
      return 'https://www.youtube.com/embed/tTO-x-pTrg0';
    },
  },
});
