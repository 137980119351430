import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { appSettings } from './appSettings';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}) => {
  // Here you can use wixCodeApi (Velo), API reference is here:
  // https://bo.wix.com/wix-docs/fe-guild/viewer-platform/ooi-reference/wixcode-apis
  // And Wix Selector ($w) to manipulate with elements on the page, API reference is here:
  // https://www.wix.com/velo/reference/$w
  // controllerConfig has more useful data, for the details look inside types and here:
  // https://bo.wix.com/wix-docs/fe-guild/viewer-platform/ooi-articles/lifecycle
  const { wixCodeApi } = controllerConfig;
  const $w = controllerConfig.$w as any;

  return {
    pageReady: () => {
      $w('@button').onClick(() => {
        $w('@text').text = 'Video was played';

        wixCodeApi.window.openModal(
          flowAPI.settings.get(appSettings.videoUrl),
          {
            width: 853,
            height: 480,
          },
        );
      });
    },
  };
};

export default createController;
